<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title class="">
        LEYENDA
        <v-chip class="mx-1 my-0" color="red" text-color="white">
          <v-icon left> mdi-alert-circle-outline </v-icon> COTIZANDO SEGURO
        </v-chip>
        <v-chip class="mx-1 my-0" color="orange" text-color="white">
          <v-icon left> mdi-alert-circle-outline </v-icon> COTIZANDO PROSPECTO
        </v-chip>
        <v-chip class="mx-1 my-0" color="yellow">
          <v-icon left> mdi-alert-circle-outline </v-icon> ESPERANDO SEGURO
        </v-chip>
        <v-chip class="mx-1 my-0" color="green" text-color="white">
          <v-icon left> mdi-alert-circle-outline </v-icon> ESPERANDO PROSPECTO
        </v-chip>
        <v-chip class="mx-1 my-0" color="default">
          <v-icon left> mdi-alert-circle-outline </v-icon> FALTA INFORMACIÓN
        </v-chip>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="redirect()" class="ml-auto">
          NUEVA COTIZACION
        </v-btn>
      </v-card-title>
      <v-row>
        <v-col cols="12">
          <v-tabs color="deep-purple accent-4" right v-model="tabs">
            <v-tab href="#quotes">Cotizaciones</v-tab>
            <v-tab href="#calls">Llamadas</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item value="quotes">
              <ListQuote />
            </v-tab-item>
            <v-tab-item value="calls">
              <ListCall />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
    <v-navigation-drawer
      v-model="$store.state.pricing.filtrarQuoteFlag"
      right
      absolute
      bottom
      persistent
      width="20%"
      temporary
    >
      <v-card elevation="10">
        <FiltroQuote />
      </v-card>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="$store.state.pricing.filtrarCallsFlag"
      right
      absolute
      bottom
      persistent
      width="20%"
      temporary
    >
      <v-card elevation="10">
        <FiltroCalls />
      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
export default {
  name: "ListPricingView",
  components: {
    ListQuote: () =>
      import("@/components/folderPricing/listQuoteComponent.vue"),
    ListCall: () => import("@/components/folderPricing/listCallComponent.vue"),
    FiltroQuote: () => import("@/components/filtros/FiltroQuote.vue"),
    FiltroCalls: () => import("@/components/filtros/FiltroCalls"),

    // ListCall: () => import("../folderPricing/comun/listCall.vue"),
  },
  data() {
    return {
      tabs: "quotes",
    };
  },
  mounted() {
    this.$store.state.drawer = false;
  },
  methods: {
    redirect() {
      this.$router.push({
        name: "newQuote",
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 50);
    },
  },
};
</script>

<style></style>
